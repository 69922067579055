<script>

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import {DASHBOARD_WHATSAPP_MESSAGES} from "@/graphql/queries/dashboard/messages";
import {CountTo} from "vue3-count-to";
import {
  UsersIcon,
  ActivityIcon,
} from '@zhuowenli/vue-feather-icons'

export default {
  name: "DashboardWhatsappMessages",
  page: {
    title: "Mensajes Whatsapp",
    meta: [{name: "description", content: appConfig.description}],
  },
  data: () => ({
    title: "Mensajes Whatsapp",
    dashboardMessages: null,
    items: [
      {
        text: "Dashboards",
        href: "/",
      },
      {
        text: "Mensajes Whatsapp",
        active: true,
      },
    ],
  }),
  components: {
    CountTo,
    Layout,
    PageHeader,
    UsersIcon,
    ActivityIcon,
  },
  apollo: {
    dashboardMessages: {
      query: DASHBOARD_WHATSAPP_MESSAGES,
      update: (data) => {
        return {...data.dashboardWhatsappMessages};
      },
      loadingKey: 'loading'
    },
  },
  computed: {
    totalForTheDay() {
      return this.dashboardMessages?.totalForTheDay;
    },
    totalForTheMonth() {
      return this.dashboardMessages?.totalForTheMonth;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <b-row>
      <b-col md="6">
        <b-card no-body class="card-animate">
          <b-card-body>
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Total Mensajes del día</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to :startVal='0' :endVal="totalForTheDay" :duration='5000'></count-to>
                </h2>
                <p class="mb-0 text-muted">
                  <b-badge class="bg-light text-success mb-0">
                    <i class="ri-arrow-up-line align-middle"></i> WH
                  </b-badge>
                  Enviados a Whatsapp
                </p>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-rose-custom rounded-circle fs-2">
                  <users-icon class="text-info icon-md text-red-custom"></users-icon>
                </span>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body class="card-animate">
          <b-card-body>
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Total Mensajes del mes</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to :startVal='0' :endVal="totalForTheMonth" :duration='5000'></count-to>
                </h2>
                <p class="mb-0 text-muted">
                  <b-badge class="bg-light text-success mb-0">
                    <i class="ri-arrow-up-line align-middle"></i> WH
                  </b-badge>
                  Enviados a Whatsapp
                </p>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-rose-custom rounded-circle fs-2">
                  <activity-icon name="activity" class="text-info icon-md text-red-custom"></activity-icon>
                </span>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </Layout>
</template>
