import gql from 'graphql-tag'

export const DASHBOARD_WHATSAPP_MESSAGES = gql`
    query {
        dashboardWhatsappMessages {
            totalForTheDay
            totalForTheMonth
        }
    }
`



